import React from "react"
import SeoHead from "../../components/global/seoHead"

import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"

import brandingFeaturedImage from "../../../static/branding/trinity-dex/TrinityDex-featured-image.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/trinity-dex.scss"
import TrinityMain from "../../components/branding/trinity-dex/trinityMain"
import TrinityFullwidth from "../../components/branding/trinity-dex/trinityFullWidth"
import TrinityMoreBranding from "../../components/branding/trinity-dex/trinityMoreBranding"

export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="TrinityDex: Revolutionary Crypto Branding | WowMakers"
            description="Explore TrinityDex's cutting-edge brand in the crypto world. Our design innovation matches their trading technology."
        />
    </>
)

const TrinityDexranding = () => {
    return (
        <Layout>
            <Seo
                bid="branding-trinity"
                bclass="case-studies-page branding"
                >
            </Seo>
            <ScrollIndicationHeightZero />
            <TrinityMain />
            <TrinityFullwidth />
            <TrinityMoreBranding />
        </Layout>
    )
}

export default TrinityDexranding
